<template>
  <v-container fluid>
    <!-- Multi Select Toolbar -->
    <v-app-bar v-if="numSelected" fixed class="mstoolbar">
      <div class="mstoolbar-back" @click="selected = []">
        <v-icon>mdi-arrow-left</v-icon>
        <span class="hidden-sm-and-down">Zurück</span>
      </div>
      <v-row class="child-flex" align="center" :style="tbContentStyles">
        <v-checkbox hide-details v-model="all" style="padding-top: 4px" />
        <div style="white-space: nowrap" class="text-h6 ml-3">
          {{ numSelected }} ausgewählt
        </div>
      </v-row>
      <v-spacer />
      <v-btn icon @click="mailClick">
        <v-icon>mdi-email</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </v-app-bar>

    <v-card>
      <v-toolbar flat dense color="grey lighten-3">
        <v-toolbar-title>Logins &amp; Berechtigungen</v-toolbar-title>
        <v-spacer />
        <v-btn id="addUser" icon @click.stop="addUser" dark color="success">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list avatar :two-line="$vuetify.breakpoint.smAndDown" ref="mlist">
        <account-tile
          v-for="account in sortedAccounts"
          :key="account.id"
          :account="account"
          :selected="isSelected(account)"
          @selected="changeSelection(account, $event)"
          @editclick="showUser(account.id)"
          @delete="deleteUser(account)"
          @save="
            (acc) => {
              currentAccount = acc;
              saveUser();
            }
          "
        />
      </v-list>
    </v-card>

    <v-dialog
      v-model="showDialog"
      max-width="50%"
      persistent
      scrollable
      :fullscreen="$vuetify.breakpoint.smAndDown"
      @keydown.esc="showDialog = false"
    >
      <edit-card
        :can-delete="!!currentAccount.id"
        @save="saveUser"
        @abort="showDialog = false"
        @delete="deleteUser(currentAccount)"
      >
        <template slot="toolbar">
          <v-toolbar-title>{{
            currentAccount.name || "Neuer Account erstellen"
          }}</v-toolbar-title>
        </template>
        <template slot="content">
          <v-form>
            <v-text-field label="Name" v-model="currentAccount.name" required />
            <v-text-field
              label="Email"
              v-model="currentAccount.email"
              :rules="emailRules"
              required
            />
            <v-autocomplete
              label="Verknüpfte Person"
              v-model="currentAccount.person_id"
              :items="persons"
              :loading="personsLoading"
              item-text="name"
              item-value="id"
              :search-input.sync="personSearchInput"
              hide-no-data
              cache-items
            >
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    [
                      [item.zip_code, item.city].filter((e) => e).join(" "),
                      item.email,
                    ]
                      .filter((e) => e)
                      .join(" / ")
                  }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
            <v-autocomplete
              label="Tourengruppe"
              :items="organisations"
              item-value="code"
              item-text="name"
              v-model="currentAccount.organisations"
              multiple
              deletable-chips
              chips
              :menu-props="{ closeOnContentClick: true }"
            />
            <v-select
              label="Rollen"
              multiple
              chips
              deletable-chips
              :items="roles"
              v-model="currentAccount.roles"
              :menu-props="{ closeOnContentClick: true }"
            />
          </v-form>
        </template>
      </edit-card>
    </v-dialog>
    <confirm ref="confirm"></confirm>
  </v-container>
</template>

<script>
import find from 'lodash/find'
import clone from 'lodash/clone'
import without from 'lodash/without'
import join from 'lodash/join'
import throttle from 'lodash/throttle'
import AccountTile from '@/components/AccountListTile'
import EditCard from '@/components/EditCard'
import Confirm from '@/components/Confirm'
import { handleApiError, createNotification } from '@/utils.js'
import { useAccountStore } from '@/stores/account'
import { mapState } from 'pinia'

import personService from '@/services/person'
import { useEventStore } from '@/stores/event'

export default {
  name: 'Accounts',
  components: { AccountTile, EditCard, Confirm },
  props: {},
  data() {
    return {
      currentAccount: {},
      showDialog: false,
      selected: [],
      all: false,
      personsResults: [],
      personsLoading: false,
      personSearchInput: '',
      initialPersons: [],
      emailRules: [
        (v) => !!v || 'Eine E-Mail Adresse ist erforderlich',
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'Die E-Mail Adresse ist ungültig',
      ],
      roles: [
        { value: 'admin', text: 'Administrator' },
        { value: 'manager', text: 'Tourenchef' },
        { value: 'guide', text: 'Leiter' },
        { value: 'emergency', text: 'Notfallstab' },
        { value: 'media', text: 'Medien' },
      ],
    }
  },
  computed: {
    numSelected() {
      return this.selected.length
    },
    tbContentStyles() {
      const lstPos = this.$refs.mlist.$el.getBoundingClientRect().left
      const offset = Math.max(lstPos + 24, 55)
      return {
        position: 'absolute',
        left: offset + 'px',
      }
    },
    persons() {
      return [...this.initialPersons, ...this.personsResults]
    },
    ...mapState(useEventStore, { organisations: state => state.meta.organisations }),
    ...mapState(useAccountStore, { sortedAccounts: 'list' }),
  },
  watch: {
    personSearchInput(text) {
      this.personsLoading = true
      this.search(text)
    },
    all(newVal) {
      if (newVal) {
        this.selected = Object.values(this.sortedAccounts)
      } else {
        this.selected = []
      }
    },
  },
  mounted() {
    this.loadUsers()
  },
  methods: {
    loadUsers() {
      console.log('Fetching Accoutns')
      useAccountStore().fetchAccounts().then(data => {
        for (const e of data) {
          if (e.person_id) {
            personService.get(e.person_id).then(data => {
              this.initialPersons.push(data)
            })
          }
        }
      })
        .catch(e => handleApiError(e, 'Fehler beim Laden der Daten.'))
    },
    showUser(id) {
      const account = find(this.sortedAccounts, i => i.id === id)
      this.currentAccount = clone(account)
      this.showDialog = true
    },
    saveUser() {
      if (this.currentAccount.id) {
        console.log('Updating existing User ' + this.currentAccount.id)
        useAccountStore().updateAccount(this.currentAccount)
          .then(() => {
            createNotification('Änderungen gespeichert', 'success')
          }).catch(e => handleApiError(e, 'Fehler beim speichern.'))
      } else {
        console.log('Creating new User')
        this.currentAccount.active = true // New accounts are active by default
        useAccountStore().createAccount(this.currentAccount)
          .then(() => {
            createNotification('Neuer Login erstellt', 'success')
          }).catch(e => handleApiError(e, 'Fehler beim speichern.'))
      }
      this.showDialog = false
    },
    deleteUser(account) {
      this.$refs.confirm.open('Löschen bestätigen', `Möchtest du <b>${account.name}</b> wirklich löschen?`, { color: 'red' }).then((confirm) => {
        if (confirm) {
          console.log('Deleting existing User ' + account.id)
          useAccountStore().deleteAccount(account)
            .then(() => {
              createNotification('Login gelöscht', 'success')
              this.showDialog = false
            }).catch(e => handleApiError(e, 'Fehler beim löschen.'))
        }
      })
    },
    addUser() {
      this.currentAccount = {
        name: '',
        email: '',
        roles: [],
      }
      this.showDialog = true
    },
    isSelected(account) {
      return this.selected.indexOf(account) >= 0
    },
    changeSelection(account, add) {
      if (add) {
        if (!this.selected.includes(account)) {
          this.selected = [...this.selected, account]
        }
      } else {
        this.selected = without(this.selected, account)
      }
    },
    mailClick() {
      console.log(join(this.selected.map(e => e.email), ', '))
    },
    search: throttle(function () {
      personService.search(this.personSearchInput)
        .then(items => {
          this.personsResults = items
          this.personsLoading = false
        })
    }, 250),
  },
}
</script>

<style lang="scss">
.mstoolbar {
  z-index: 4;

  .mstoolbar-back {
    cursor: pointer;

    span {
      margin-left: 0.5em;
    }

    &:hover {
      i {
        font-weight: 700;
      }
    }
  }
}
</style>
